import { Controller } from "@hotwired/stimulus"

import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ["address", 'form'];

  connect() {
    if (typeof (google) != "undefined") {
      this.initializeAutocomplete();
    }
  }

  disconnect() {
    const element = this.addressTarget;
    if (element) {
      $(element).remove();
    }
  }

  initializeAutocomplete() {
    const element = this.addressTarget;

    if (element) {
      var autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
      google.maps.event.addListener(autocomplete, 'place_changed', this._populateFullAddress);
    }
  }

  _populateFullAddress() {
    let place = this.getPlace();
    let full_address_el = document.getElementById('full_address');
    let address_line_1_el = document.getElementById('address_line_1');
    let latitude_el = document.getElementById('latitude');
    let longitude_el = document.getElementById('longitude');

    if (full_address_el || address_line_1_el) {
      if (full_address_el) full_address_el.value = place.formatted_address
      if (address_line_1_el) address_line_1_el.value = place.name;
      if (latitude_el && longitude_el) {
        latitude_el.value = place.geometry.location.lat();
        longitude_el.value = place.geometry.location.lng();
      }

      for (let i in place.address_components) {
        let component = place.address_components[i];
        for (var j in component.types) {
          // component.types we care about:
          // locality/sublocality -> city
          // administrative_area_level_1 -> us state
          // postal_code -> zip_code

          let type_element = document.getElementById(component.types[j]);
          if (component.types[j] === 'locality' || component.types[j] === 'sublocality') {
            type_element = document.getElementById('locality')
          }

          // auto populate address fields from the option the user selected
          if (type_element) {
            type_element.value = component.long_name;
          }
        }
      }
    }
  }
}
