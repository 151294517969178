import { Controller } from "@hotwired/stimulus"

import COUNTRIES from "../helpers/country_list";

export default class extends Controller {
  static targets = ['editable', 'display'];

  showClassMapping = {
    'editable-show-email': 'editable-hidden-email',
    'editable-show': 'editable-hidden',
    'edit-email-show': 'edit-email-hidden',
    'edit-phone-show': 'edit-phone-hidden',
    'editable-show-phone': 'editable-hidden-phone'
  };

  startEdit(event) {
    this.stopInputPropagation(event);

    this._showElement(this.editableTarget);
    this.editableTarget.value = event.target.innerText;
    this.editableTarget.focus();

    this._hideElement(this.displayTarget);
  }

  startEditPhone(event) {
    this.stopInputPropagation(event);

    this._showElement(this.editableTarget);
    this.editableTarget.value = this.displayTarget.innerText;
    this.editableTarget.focus();

    this._hideElement(this.displayTarget);
    const icon = document.getElementById('call');
    this._hideElement(icon);
    const phoneIcon = document.getElementById('phone-icon');
    this._hideElement(phoneIcon);
  }

  startEditEmail(event) {
    this.stopInputPropagation(event);

    this._showElement(this.editableTarget);
    this.editableTarget.value = this.displayTarget.innerText;
    this.editableTarget.focus();

    this._hideElement(this.displayTarget);
    const icon = document.getElementById('email-icon');
    this._hideElement(icon);
  }

  openDialpad(event) {
    const phoneNumber = this.displayTarget.innerText
    const country = document.getElementById('country').value
    const countryCode = this.getCountryCode(country)
    const appLaunchUrl = "dialpad://" + countryCode + phoneNumber + "?confirmPhone=1";
    window.location.href = appLaunchUrl;
  }

  copyEmail(event) {
    const emailField = this.displayTarget;
    emailField.value = event.target.innerText;
    if (emailField) {
      const emailText = emailField.value;
      navigator.clipboard.writeText(emailText).then(() => {
        this.showFlashMessage('Email copied to clipboard', emailField);
      }).catch((err) => {
        console.error("Failed to copy email to clipboard: ", err);
      });
    }
  }

  getCountryCode(country) {
    return COUNTRIES.find(c => c.name === country).mobileCode
  }

  copyPhoneNumber(event) {
    const phoneNumber = event.target.innerText;
    navigator.clipboard.writeText(phoneNumber).then(() => {
      this.showFlashMessage('Phone number copied to clipboard');
    }).catch((err) => {
      console.error("Failed to copy phone number to clipboard: ", err);
    });
  }

  copyScreenerAttemptID(event) {
    const screenerAttemptID = event.target.innerText;
    navigator.clipboard.writeText(screenerAttemptID).then(() => {
      this.showFlashMessage('Screener Attempt ID copied to clipboard');
    }).catch((err) => {
      console.error("Failed to copy Screener Attempt ID to clipboard: ", err);
    });
  }

  showFlashMessage(message) {
    if (!document.getElementById('flash-message')){ //only create one message
      const flashMessage = document.createElement("div");
      flashMessage.classList.add("flash-message");
      flashMessage.textContent = message;
      flashMessage.setAttribute('id', 'flash-message')

      if (message.includes('Email')){
        const icon = document.getElementById('email-icon')
        icon.insertAdjacentElement("afterend", flashMessage);
      } else if (message.includes('Phone')){
        flashMessage.style.top = "-10px";
        const icon = document.getElementById('call')
        icon.insertAdjacentElement("afterend", flashMessage);
      } else {
        flashMessage.style.left = "10px";
        const div = document.getElementById('screener-attempt-flash-message')
        div.appendChild(flashMessage);
      }
      setTimeout(() => {
        flashMessage.remove();
      }, 5000); // Remove the flash message after 5 seconds
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === this.constructor.KeyEnter) {
      this._saveEdit();
    }
    if (event.keyCode === this.constructor.KeyEscape) {
      this.pauseSaving = true
      this._abandonEdit();
    }
  }

  handleBlur(event) {
    if (!this.pauseSaving) this._saveEdit();
    this.pauseSaving = false;
  }

  _abandonEdit() {
    this._showElement(this.displayTarget);
    this._hideElement(this.editableTarget);
  }

  _saveEdit() {
    if (this.autosaveController) {
      this.autosaveController.save();
    }

    this.displayTarget.innerText = this.editableTarget.value;
    this._showElement(this.displayTarget);
    this._hideElement(this.editableTarget);
    const icons = ['email-icon', 'phone-icon', 'call']
    icons.forEach((icon) => {
      const iconEl = document.getElementById(icon)
      this._showElement(iconEl)
    })
  }

  stopInputPropagation(event) {
    // to stop saving when repeatedly clicking inside of the input
    event.preventDefault();
    // to stop the the accordion from opening/closing on click
    event.stopPropagation();
  }

  _showElement(el) {
    for (const [showClass, hiddenClass] of Object.entries(this.showClassMapping)) {
      if (el.classList.contains(hiddenClass)) {
        el.classList.remove(hiddenClass);
        el.classList.add(showClass);
        break;
      }
    }
  }

  _hideElement(el) {
    for (const [showClass, hideClass] of Object.entries(this.showClassMapping)) {
      if (el.classList.contains(showClass)) {
        el.classList.remove(showClass);
        el.classList.add(hideClass);
        break;
      }
    }
  }

  get autosaveController() {
    return this.application.getControllerForElementAndIdentifier(this.editableTarget.form, "autosave")
  }

  static get KeyEnter() { return 13; }
  static get KeyEscape() { return 27; }
}