import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["question"]
  static values = {
    screenerId: Number
  }

  submitNewOrder() {
    const data = new FormData();

    this.questionTargets.forEach((question, index) => {
      data.append(`new_indices[${question.dataset.id}]`, index)
    });

    Rails.ajax({
      url: `/admin/screener_questions/replace_order`,
      type: 'PATCH',
      data: data
    })
  }
}
