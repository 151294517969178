import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    query: String,
  };

  connect() {
    console.log("connected");
  }

  setCustomValidity() {
    console.log("setCustomValidity");
    console.log("queryValue:", this.queryValue);
    let answers = document.querySelectorAll(`[id^="${this.queryValue}"]`);
    console.log(answers);
    if (answers) {
      answers.forEach((answer) => {
        answer.setCustomValidity("");
      });
    }
  }
}
